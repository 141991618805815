import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';

function Buttons({ hasApplied, declineModal, deleteModal, talent, job, recruiterId }) {
	const ACTIVE_ROLE = service.getUserType();
	const [isLoading] = useState(false);
	const [applicants, setApplicants] = useState([]);
	const navigate = useNavigate();
	const [subscribed, setSubscribed] = useState(null);

	const handleContactClick = () => {
		navigate(`/dashboard/conversations/${recruiterId}/${talent}`);
	};

	useEffect(() => {
		function fetchApplicants() {
			api.recruiter
				.getJobApplicants(job)
				.then((response) => {
					const result = response.data?.result;
					setApplicants(result || []); // Ensure applicants is an array
				})
				.catch((err) => console.log(err));
		}

		if (job) {
			fetchApplicants();
		}
	}, [job]);

	// Check if the talentId exists in the fetched applicants list
	const talentExists = applicants.some((applicant) => applicant.talent?.id === talent);

	// Disable the button if the talent is not found
	const isDisabled = !talentExists;
	useEffect(() => {
		api.recruiter
			.getJobHireSubscription(job)
			.then((res) => setSubscribed(res.data.result))
			.catch(console.error);
	}, [job]);

	const handleHire = () => {
		if (subscribed === null) {
			navigate(`/dashboard/jobs/recruiter/hire/${job}/${talent}?section=1`);
		} else {
			navigate(`/dashboard/jobs/recruiter/hire/${job}/${talent}?section=2`);
		}
	};

	return (
		<>
			{ACTIVE_ROLE === service.RECRUITER && (
				<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 gap-lg-2">
					<Button small fadeFill onClick={''} text={<h1>Report</h1>} />
					<Button
						small
						fadeFill
						onClick={''}
						text={
							<>
								<i class="fa-solid fa-thumbs-up"></i>
							</>
						}
						disabled={isLoading}
					/>
					<Button
						small
						fadeFill
						onClick={''}
						text={
							<>
								<i class="fa-solid fa-thumbs-down"></i>
							</>
						}
						disabled={isLoading}
					/>
					<Button
						small
						primaryNull
						text={
							<>
								<i class="fa-solid fa-message"></i>
								<h1>Contact Talent</h1>
							</>
						}
						onClick={handleContactClick}
					/>
					{job && (
						<Button
							small
							primaryNull
							disabled={isDisabled}
							text={
								<>
									<i class="fa-solid fa-suitcase"></i>
									<h1>Hire</h1>
								</>
							}
							onClick={handleHire}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default Buttons;
