import Button from 'components/buttons/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';

function Buttons({ hasApplied, declineModal, deleteModal, id, job }) {
	const navigate = useNavigate();
	const ACTIVE_ROLE = service.getUserType();

	const [isArchived, setIsArchived] = useState(job?.status === 'archive');
	const [isLoading, setIsLoading] = useState(false);

	function doHandleArchive() {
		setIsLoading(true);
		const newStatus = isArchived ? 'published' : 'archive';
		api.recruiter
			.updateJob(id, { status: newStatus })
			.then(() => {
				setIsLoading(false);
				setIsArchived(!isArchived);
			})
			.catch((res) => console.log(res));
	}

	function applyModal() {
		return {
			modal: true,
			doDismissModal: true,
			modalTarget: 'job-details-modal-a',
		};
	}

	return (
		<>
			{ACTIVE_ROLE === service.RECRUITER ? (
				<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 gap-lg-2">
					<Button small secondaryNull onClick={() => navigate(-1)} text={<h1>Back</h1>} />
					<Button small primaryNull onClick={doHandleArchive} text={<h1>{job?.status === 'archive' && isArchived ? `Publish` : `Archive`}</h1>} disabled={isLoading} />
					<Button small primaryNull text={<h1>{job?.isJobPromoted === true ? `Promoted` : `Promote`}</h1>} disabled={isLoading && job?.isJobPromoted === true} redirect={`/dashboard/jobs/promote/${id}?section=1`} />
					<Button
						small
						text={
							<>
								<h1>Edit</h1>
								<i className="fa-solid fa-arrow-right"></i>
							</>
						}
						redirect={`/dashboard/jobs/edit/${id}`}
					/>
					<Button
						small
						primaryNull
						text={
							<>
								<i className="fa-regular fa-trash-can"></i>
								<h1>Delete</h1>
							</>
						}
						{...deleteModal()}
					/>
				</div>
			) : (
				<div className="d-lg-flex align-items-center gap-2">
					<div className="mb-3 mb-lg-0">
						<Button small primaryNull onClick={() => navigate(-1)} text={<h1>Back</h1>} />
					</div>

					<div className="mb-3 mb-lg-0">
						{hasApplied ? (
							<Button small text={<h2>Applied</h2>} />
						) : (
							<Button
								small
								text={
									<>
										<h2>Apply Now</h2>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
								{...applyModal()}
							/>
						)}
					</div>

					{hasApplied && (
						<div className="mb-3 mb-lg-0">
							<Button
								small
								darkFill
								text={
									<>
										<h2>Decline</h2>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
								{...declineModal()}
							/>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default Buttons;
