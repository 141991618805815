import Button from 'components/buttons/Button';
import MessageForm from 'components/forms/message-form/MessageForm.jsx';
import Modal from 'components/modals/Modal';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import service from 'services';
import classNames from 'utils/conditional-css-classes/classNames';
import { freelancerValidationSchema } from 'utils/schemas/register.schema';
import OnboardingModal from 'views/register/onboarding/freelancer/modals/onboarding-modal/Modal';
import LoaderButton from '../../loader/loader-btn/LoaderButton';
import './css/LoginForm.style.sass';
import routes from '../../../services/routes';
import { useNavigate } from 'react-router-dom';
import Previous from 'views/onboarding/talent/previous/Previous';

function SubForm({ noModals, callback, isLoading }) {
	const [query] = useSearchParams();
	const [isPassword, setIsPassword] = useState(false);

	function doViewPassword() {
		setIsPassword(!isPassword);
	}

	const forms = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			password2: '',
		},
		validationSchema: freelancerValidationSchema,
		onSubmit: (values) => callback(values),
	});

	const modalProps = {
		modal: true,
		isStatic: true,
		modalWidth: '700px',
		modalTitle: 'congratulations',
		modalBody: <OnboardingModal value={forms.values.email} />,
		modalTarget: 'freelancer-modal',
	};

	return (
		<div id="SubForm_Form_Container">
			<div className="con-header d-flex flex-column align-items-center gap-3 mb-4">
				<h1 className="header_text">Frontter is all about you</h1>
				<span className="d-flex align-items-start w-100">
					{/* or &nbsp;
					<i className="link">
						<Link to="/login">sign in</Link>
					</i>
					&nbsp;to your existing account */}

					<Previous />
				</span>
				<div className="w-100 d-flex align-items-center">
					<div className="line"></div>
					<h1 className="text-center">
						Register as a {query.get('tab') === service.TALENT && `Job Seeker`}
						{query.get('tab') === service.RECRUITER && `Recruiter`}
						{query.get('tab') === service.FREELANCER && `Freelancer`}
					</h1>
					<div className="line"></div>
				</div>

				{query.get('tab') !== service.RECRUITER && (
					<div className="con-button  align-items-center gap-3 d-none">
						<div className="mb-4 mb-lg-0">
							<Button small text={<h2>Job Seeker</h2>} fadeNull={query.get('tab') !== service.TALENT} redirect="/register?tab=talent" />
						</div>

						<div>
							<Button small text={<h2>Freelancer</h2>} fadeNull={query.get('tab') !== service.FREELANCER} redirect="/register?tab=freelancer" />
						</div>
					</div>
				)}
			</div>

			<form>
				<div className="d-lg-flex justify-content-between gap-4">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>First Name</label>
						</div>
						<input type="text" name="firstName" placeholder="Enter First Name" className={forms.errors.firstName && forms.touched.firstName ? 'error' : ''} value={forms.values.firstName} onChange={forms.handleChange} />
						<label className="error-msg mb-3">{forms.errors.firstName && forms.touched.firstName ? forms.errors.firstName : null}</label>
					</div>

					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Last Name</label>
						</div>
						<input type="text" name="lastName" placeholder="Enter Last Name" className={forms.errors.lastName && forms.touched.lastName ? 'error' : ''} value={forms.values.lastName} onChange={forms.handleChange} />
						<label className="error-msg mb-3">{forms.errors.lastName && forms.touched.lastName ? forms.errors.lastName : null}</label>
					</div>
				</div>

				<div className="d-lg-flex justify-content-between gap-4">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Email</label>
						</div>
						<input type="email" name="email" placeholder="Enter Email Address" className={forms.errors.email && forms.touched.email ? 'error' : ''} value={forms.values.email} onChange={forms.handleChange} style={{ textTransform: 'none' }} />
						<label className="error-msg mb-3">{forms.errors.email && forms.touched.email ? forms.errors.email : null}</label>
					</div>

					<div className="control-form con-password">
						<div className="d-flex justify-content-between">
							<label>Password</label>
						</div>

						<div className="password">
							<input
								type={isPassword ? 'text' : 'password'}
								name="password"
								placeholder="Enter password"
								className={forms.errors.password && forms.touched.password ? 'error' : ''}
								value={forms.values.password}
								onChange={forms.handleChange}
								style={{ textTransform: 'none' }}
							/>

							{isPassword ? <i className="fa-regular fa-eye-slash" onClick={doViewPassword}></i> : <i className="fa-regular fa-eye" onClick={doViewPassword}></i>}
						</div>
						<label className="error-msg">{forms.errors.password && forms.touched.password ? forms.errors.password : null}</label>
						<div className="password-indicators d-flex gap-1 justify-content-evenly align-items-start" style={{ marginTop: '-20px', marginLeft: '-15px' }}>
							<h4 className="d-flex gap-2" style={{ fontSize: '12px', flexWrap: 'wrap' }}>
								<p style={{ whiteSpace: 'nowrap' }}>Must contain</p>
								<p className={` ${forms.values.password.match(/[a-z]/) ? 'text-success' : 'text-muted'}`} style={{ whiteSpace: 'nowrap' }}>
									small letter
								</p>
								<p className={` ${forms.values.password.match(/[A-Z]/) ? 'text-success' : 'text-muted'}`} style={{ whiteSpace: 'nowrap' }}>
									capital letter
								</p>
								<p className={` ${forms.values.password.match(/[@$!%*?&]/) ? 'text-success' : 'text-muted'}`} style={{ whiteSpace: 'nowrap' }}>
									special characters & numbers
								</p>
								<p className={` ${forms.values.password.length >= 8 ? 'text-success' : 'text-muted'}`} style={{ whiteSpace: 'nowrap' }}>
									up to 8 characters
								</p>
							</h4>
						</div>
					</div>
				</div>

				<div className="d-lg-flex justify-content-between gap-4">
					<div className="control-form con-password">
						<div className="d-flex justify-content-between">
							<label>Confirm Password</label>
						</div>

						<div className="password">
							<input
								type={isPassword ? 'text' : 'password'}
								name="password2"
								placeholder="Confirm password"
								className={forms.errors.password2 && forms.touched.password2 ? 'error' : ''}
								value={forms.values.password2}
								onChange={forms.handleChange}
								style={{ textTransform: 'none' }}
							/>

							{isPassword ? <i className="fa-regular fa-eye-slash" onClick={doViewPassword}></i> : <i className="fa-regular fa-eye" onClick={doViewPassword}></i>}
						</div>
						<label className="error-msg mb-3">{forms.errors.password2 && forms.touched.password2 ? forms.errors.password2 : null}</label>
					</div>

					<i className="control-form"></i>
				</div>

				{/* BUTTON TO INITIALIZE MODAL ON CONDITIONS */}
				<>
					{!forms.errors.firstName &&
					forms.values.firstName &&
					!forms.errors.lastName &&
					forms.values.lastName &&
					!forms.errors.email &&
					forms.values.email &&
					!forms.errors.password &&
					forms.values.password &&
					!forms.errors.password2 &&
					forms.values.password2 &&
					!noModals ? (
						<div className="control-form d-flex justify-content-center">
							<Button
								text={
									<>
										<h1>Sign Up</h1>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
								{...modalProps}
								onClick={forms.handleSubmit}
							/>
						</div>
					) : (
						<div className="control-form d-flex justify-content-center">
							<Button text={<LoaderButton isLoading={isLoading} text="Sign Up" />} onClick={forms.handleSubmit} disabled={isLoading} />
						</div>
					)}
				</>

				<Modal {...modalProps} />
			</form>
		</div>
	);
}

function LoginForm({ emailLabelText, submitBtnText, onSubmit, ForRegister, loadingStatus, errorMsg, errorCallback, ...props }) {
	const navigate = useNavigate();
	const [query] = useSearchParams();
	const location = useLocation();

	const [isPassword, setIsPassword] = useState(false);
	const [form, setForm] = useState({ email: '', password: '' });

	const [formErrors, setFormErrors] = useState(form);

	// const [recoveryemail, setRecoveryEmail] = useState('')
	const userTab = query.get('tab');

	// const createOTP = () => {
	// 	if (form.email) {
	// 		const OTP = Math.floor(Math.random() * 9000 + 1000)

	// 		console.log(form.email)
	// 		// setRecoveryEmail(form.email)
	// 		// setOTP(OTP)

	// 		// otp && console.log(otp)

	// 		navigate('/otp', { state: { otp: OTP, recoveryemail: form.email } })

	// 	} else {
	// 		alert('Please Enter Email')
	// 	}

	// }

	function handleOnChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		// console.log(value)

		setForm({ ...form, [`${name}`]: value });
		setFormErrors({ ...formErrors, [`${name}`]: `` });
	}

	function hasErrors() {
		for (const field in form) {
			if (!form[field]) {
				const formElement = document.forms[0].elements;
				formElement[field]?.focus();
				formElement[field]?.scrollIntoView({ block: 'center' });
				setFormErrors({ ...formErrors, [`${field}`]: 'field is required' });

				console.warn(`{${field}} field is required`);
				return true;
			}
		}

		return false;
	}

	function doSubmit(evt) {
		evt?.preventDefault();

		if (!hasErrors()) {
			onSubmit(form);
		}
	}

	function viewPassword() {
		setIsPassword(!isPassword);
	}

	return (
		<div id="LoginForm_Main_Container">
			<div>
				{ForRegister ? (
					<SubForm {...props} />
				) : (
					<form onSubmit={doSubmit}>
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>{emailLabelText || `Email`}</label>

								<label className="error-msg mb-3">{formErrors.email}</label>
							</div>
							<input type="email" name="email" value={form.email} placeholder="Enter email address" className={classNames(formErrors.email && 'error')} onChange={handleOnChange} style={{ textTransform: 'lowercase' }} />
						</div>

						<div className="control-form con-password">
							<div className="d-flex justify-content-between">
								<label>Password</label>
								<label className="error-msg mb-3">{formErrors.password}</label>
							</div>

							<div className={`password ${classNames(formErrors.password && 'error')}`}>
								<input type={isPassword ? 'text' : 'password'} name="password" placeholder="Enter password" value={form.password} onChange={handleOnChange} style={{ textTransform: 'none' }} />

								{isPassword ? <i className="fa-regular fa-eye-slash" onClick={viewPassword}></i> : <i className="fa-regular fa-eye" onClick={viewPassword}></i>}
							</div>
						</div>

						<div className="control-form d-flex align-items-center justify-content-between">
							<Button text={<LoaderButton isLoading={loadingStatus} text="Sign In" />} onClick={doSubmit} disabled={loadingStatus} buttonType={'submit'} />

							<div className="forget_password">
								<p href="#" style={{ cursor: 'pointer' }} onClick={() => navigate('/verifyemail')}>
									Forget Password
								</p>
							</div>
						</div>
					</form>
				)}

				{errorMsg && <MessageForm message={errorMsg} />}

				<div className="con-underline d-flex align-items-center gap-3">
					<div></div>
					<h2>or</h2>
					<div></div>
				</div>
				{/* SIGNUP AREA */}
				<div className="con-socials d-lg-flex justify-content-center">
					<div className="d-lg-flex align-items-center justify-content-center gap-2">
						{/* Google */}
						<div className="mb-3">
							<a
								rel="noopener noreferrer"
								href={location.pathname.includes('/login') ? routes['google-login']() : `${routes['google-register'](userTab)}`}
								className="d-flex align-items-center gap-2 px-3 py-2 border border-2 rounded-pill justify-content-center">
								{' '}
								<img src="img/Icons.png" alt="" />
								<h1 className="h6">With Google</h1>
							</a>
						</div>
						{/* Facebook */}
						{/* <div className="mb-3">
							<a
								rel="noopener noreferrer"
								href={location.pathname.includes('/login') ? routes['facebook-login']() : `${routes['facebook-register'](userTab)}`}
								className="d-flex align-items-center gap-2 px-3 py-2 border border-2 rounded-pill justify-content-center">
								{' '}
								<img src="img/Icons (1).png" alt="" />
								<h1 className="h6">With Facebook</h1>
							</a>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default LoginForm;
