import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import service from 'services';
import api from 'services/api';

function ModalBody({ title, description, company_link, company_name, country, state, expected_pay, callback, currency, preferred_gender, specialty }) {
	const modalProps = {
		modal: true,
		doDismissModal: true,
		modalTarget: 'post-opportunity-submitted',
	};
	const [company_logo, setCompanyLogo] = useState(null);
	useEffect(() => {
		api.recruiter.profile.get().then((response) => setCompanyLogo(response?.data?.result?.company_logo?.href_link));
	}, []);

	return (
		<div id="target-opportunity-modal">
			<div className="con-modal-body">
				<div className="section-a mb-4">
					<div className="header d-lg-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center gap-4 gap-lg-3 mb-3 mb-lg-0">
							<div className="img">
								<img src={company_logo || '/img/avatar.png'} alt="" />
							</div>

							<div className="body">
								<h2>{title || `C++ Developer`}</h2>
								<h3>{service.titleCase(company_name)}</h3>
								<p className="d-flex align-items-center gap-1">
									<i className="fa-solid fa-location-dot"></i>
									{state && country ? `${state}, ${country}` : 'Worldwide'}
								</p>

								<p className="d-flex align-items-center gap-1">
									<i className="fa-solid fa-globe"></i>
									<Link to="/register?tab=recruiter&section=004" href="./">
										{company_link}
									</Link>
								</p>
							</div>
						</div>

						<div className="con-button d-flex align-items-center gap-2">
							<div className="button-1">
								<Button
									fadeFill
									text={
										<>
											<h1>Apply now</h1>
											<i className="fa-solid fa-arrow-right"></i>
										</>
									}
								/>
							</div>

							<div className="button-2">
								<Button fadeFill text={<i className="fa-solid fa-xmark" data-bs-dismiss="modal"></i>} />
							</div>
						</div>
					</div>

					<div className="social d-flex align-items-center gap-4">
						<p>social links:</p>
						<Link>
							<i className="fa-brands fa-linkedin-in"></i>
						</Link>
						<Link>
							<i className="fa-brands fa-instagram"></i>
						</Link>
						<Link>
							<i className="fa-solid fa-basketball"></i>
						</Link>

						<Link>
							<i className="fa-brands fa-github"></i>
						</Link>
						<Link>
							<i className="fa-brands fa-gitlab"></i>
						</Link>
					</div>
				</div>

				<div className="section-b mb-3">
					<div className="header d-flex align-items-center gap-3">
						<i className="fa-solid fa-screwdriver-wrench"></i>
						<h2>Job Description</h2>
					</div>

					<div className="body">
						<p>{description && service.transform(description)}</p>
					</div>
				</div>

				<div className="section-c mb-2">
					<div className="header d-flex align-items-center gap-2 mb-3">
						<i className="fa-solid fa-dollar-sign"></i>
						<h2>Expected Pay</h2>
					</div>

					<div className="body">
						<h2>
							{currency} {expected_pay}
						</h2>
					</div>
				</div>

				<div className="section-c mb-2">
					<div className="header d-flex align-items-center gap-2 mb-3">
						<h2>Preferred gender</h2>
					</div>

					<div className="body">
						<h2>{preferred_gender}</h2>
					</div>
				</div>

				<div className="section-c ">
					<div className="header d-flex align-items-center gap-2 mb-3">
						<i class="fa-solid fa-briefcase"></i>
						<h2>Skills Required</h2>
					</div>
					<ul>
						{specialty?.map((skill, index) => (
							<li key={index} className="skill_list">
								{skill}
							</li>
						))}
					</ul>
				</div>

				<div className="footer d-flex align-items-center gap-3">
					<div data-bs-dismiss="modal">
						<Button primaryNull small text={<h2>Cancel</h2>} />
					</div>
					<div>
						<Button
							small
							text={
								<>
									<h2>Post Opportunity</h2>
									<i className="fa-solid fa-arrow-right"></i>
								</>
							}
							onClick={callback}
							{...modalProps}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export const handleModal = (props) => {
	return {
		modal: true,
		isStatic: true,
		modalWidth: '900px',
		modalTarget: 'post-opportunity',
		modalTitle: 'opportunity post review',
		modalBody: <ModalBody {...props} />,
	};
};
