import axios from 'axios';
import service from 'services';
import routes from 'services/routes';
import { auth } from './auth';

const accessToken = service.getTokensLocalStorage()?.access?.token;
const token = service.getTokensLocalStorage();

axios.interceptors.request.use((config) => {
	if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
	return config;
}, Promise.reject);

axios.interceptors.response.use(
	(res) => res,
	async (err) => {
		const originalConfig = err?.config;
		const instance = axios.create();

		try {
			if (err.response?.data?.status === 401 && ['jwt expired', ''].includes(err.response?.data?.result) && !originalConfig._retry) {
				originalConfig._retry = true;
				const response = await auth.refreshToken(token?.refresh?.token);

				token.access = response?.data?.result?.access;

				service.removeTokensLocalStorage();
				service.setTokensLocalStorage(token);

				originalConfig.headers['Authorization'] = `Bearer ${response?.data?.result?.access?.token}`;

				return instance(originalConfig);
			}
		} catch (err) {
			return Promise.reject(err);
		}
		return Promise.reject(err?.response);
	}
);

const api = {
	resetPasswordRequest: {
		resetpasswordrequest: async (postBody) => {
			return await axios.post(routes['reset-password-request'](), postBody);
		},
	},
	sendMessage: async (postBody) => {
		return await axios.post(routes['send-message'](), postBody);
	},
	getMessages: async (id, reader, limit = 10, page = 1) => {
		return await axios.get(routes['get-messages'](id), { params: { limit, page, reader } });
	},
	getCustomMessages: async () => {
		return await axios.get(routes['custom-messages']());
	},
	subscriptions: {
		getSubscriptionPlans: async (type) => {
			return await axios.get(routes.subscriptions.subscriptions(), { params: { type } });
		},
		getSingleSubscription: async (id) => {
			return await axios.get(routes.subscriptions['get-single-subscription'](id));
		},
	},
	adverts: {
		getRooms: async () => {
			return await axios.get(routes.adverts['get-rooms']());
		},
		getSpaces: async (roomId) => {
			return await axios.get(routes.adverts['get-spaces'](roomId));
		},
	},
	contactUs: {
		contact: async (postBody) => {
			return await axios.post(routes['contact-us'](), postBody);
		},
		advertise: async (postBody) => {
			return await axios.post(routes['advertise-with-us'](), postBody);
		},
		getusers: async (size = 7) => {
			return await axios.get(routes['get-users'](), { params: { size } });
		},
	},
	recruiter: {
		profile: {
			get: async () => {
				return await axios.get(routes.recruiter.profile['get-details']());
			},
			update: async (postBody) => {
				return await axios.put(routes.recruiter.url(), postBody);
			},
			uploadImage: async (postBody) => {
				return await axios.post(routes.recruiter.profile['upload-image'](), postBody);
			},
			uploadCompanyImage: async (postBody) => {
				return await axios.post(routes.recruiter.profile['upload-company-image'](), postBody);
			},
			uploadCompanyCac: async (postBody) => {
				return await axios.post(routes.recruiter['company-cac'](), postBody);
			},
		},
		conversations: {
			initiateConversation: async (postBody) => {
				return await axios.post(routes.recruiter.conversations['initiate-conversation'](), postBody);
			},
			getConversations: async (pageNumber = 1, pageSize = 14) => {
				return await axios.get(routes.recruiter.conversations['get-conversations'](), { params: { pageNumber, pageSize } });
			},
		},
		getJob: async (id) => {
			return await axios.get(routes.recruiter['job-id'](id));
		},
		createJob: async (postBody) => {
			return await axios.post(routes.recruiter.jobs(), postBody);
		},
		updateJob: async (id, postBody) => {
			return await axios.put(routes.recruiter['job-id'](id), postBody);
		},
		deleteJob: async (id) => {
			return await axios.delete(routes.recruiter['job-id'](id));
		},
		getMyJobs: async (page = 1, limit = 10) => {
			return await axios.get(routes.recruiter.jobs(), { params: { page, limit, recruiter: service.getUserID() } });
		},
		jobsStats: async () => {
			return await axios.get(routes.recruiter['jobs-stats']());
		},
		promoteJob: async (id, postBody) => {
			return await axios.post(routes.recruiter['promote-job'](id), postBody);
		},
		findTalents: async (queryParams, page = 1, limit = 10) => {
			return await axios.get(routes.recruiter['find-talents'](), { params: { page, limit, ...queryParams } });
		},
		onboarding: async (postBody) => {
			return await axios.put(routes.recruiter.url(), postBody);
		},
		getTalent: async (id) => {
			return await axios.get(routes.recruiter['get-talent'](id));
		},
		getTalentsProject: async (id, page = 1, limit = 14) => {
			return await axios.get(routes.recruiter['get-talents-project'](id), { params: { page, limit } });
		},
		getTalentsSingleProject: async (id) => {
			return await axios.get(routes.recruiter['get-talents-single-project'](id));
		},
		getJobTitles: async (page = 1, limit = 100) => {
			return await axios.get(routes.recruiter['get-job-titles'](), { params: { page, limit } });
		},
		getAppliedJobs: async (page = 1, limit = 20) => {
			return await axios.get(routes.recruiter['get-applied-jobs'](), { params: { page, limit } });
		},
		getJobApplicants: async (id, page = 1, limit = 100) => {
			return await axios.get(routes.recruiter['get-job-applicants'](id), { params: { page, limit } });
		},
		hireTalent: async (id, postBody) => {
			return await axios.post(routes.recruiter['hire-talent'](id), postBody);
		},
		getJobHireSubscription: async (id) => {
			return await axios.get(routes.recruiter['get-job-hire-subscription'](id));
		},
		initiatePaymentForJob: async (id, postBody) => {
			return await axios.post(routes.recruiter['initiate-payment-for-job'](id), postBody);
		},
		getRecommendedTalents: async (id, page = 1, limit = 100, all = true) => {
			return await axios.get(routes.recruiter['get-recommended-talents'](id), { params: { page, limit, all } });
		},
	},
	talents: {
		createFeedback: async (postBody) => {
			return await axios.post(routes.talents.feedback(), postBody);
		},
		applyCoupon: async (code) => {
			return await axios.get(routes.talents['apply-coupon'](code));
		},
		subscriptions: {
			initiateSubscription: async (postBody) => {
				return await axios.post(routes.talents.subscriptions['initiate-subscription'](), postBody);
			},
			getSubscriptions: async () => {
				return await axios.get(routes.talents.subscriptions['get-subscription']());
			},
			verifySubscriptions: async (id) => {
				return await axios.get(routes.talents.subscriptions['verify-subscription'](id));
			},
		},
		refer: {
			referTalent: async (postBody) => {
				return await axios.post(routes.talents.refer(), postBody);
			},
		},
		getBadges: async (id) => {
			return await axios.get(routes.talents['get-badges'](id));
		},
		profile: {
			get: async () => {
				return await axios.get(routes.talents.profile['get-details']());
			},
			update: async (postBody) => {
				return await axios.put(routes.talents.url(), postBody);
			},
			completeStatus: async () => {
				return await axios.get(routes.talents.profile['get-complete']());
			},
			uploadImage: async (postBody) => {
				return await axios.post(routes.talents.profile['upload-image'](), postBody);
			},
			addCertificate: async (postBody) => {
				return await axios.post(routes.talents.profile['add-certificate'](), postBody);
			},
			getCertificates: async (page = 1, limit = 100) => {
				return await axios.get(routes.talents.profile['add-certificate'](), { params: { page, limit } });
			},
			deleteCertificate: async (certId) => {
				return await axios.delete(routes.talents.profile['delete-certificate'](certId));
			},
		},
		jobs: {
			job: async (jobId) => {
				return await axios.get(routes.talents.jobs.id(jobId));
			},
			recommended: async (page = 1, limit = 10) => {
				return await axios.get(routes.talents.jobs.recommended(), { params: { page, limit } });
			},
			applyToJob: async (jobId, postBody) => {
				return await axios.post(routes.talents.jobs['apply-to-job'](jobId), postBody);
			},
			applyToJobQuestions: async (jobId, postBody) => {
				return await axios.patch(routes.talents.jobs['apply-to-job'](jobId), postBody);
			},
			declineApplyToJob: async (applicationId) => {
				return await axios.delete(routes.talents.jobs['decline-apply-to-job'](applicationId));
			},
			addExperience: async (postBody) => {
				return await axios.post(routes.talents.jobs['add-experience'](), postBody);
			},
			deleteExperience: async (id) => {
				return await axios.delete(routes.talents.jobs['delete-experience'](id));
			},
			addEducation: async (postBody) => {
				return await axios.post(routes.talents.jobs['add-education'](), postBody);
			},
			deleteEducation: async (id) => {
				return await axios.delete(routes.talents.jobs['delete-education'](id));
			},
			addToBookmark: async (jobId) => {
				return await axios.post(routes.talents.jobs['bookmark-by-id'](jobId));
			},
			removeFromBookmark: async (jobId) => {
				return await axios.delete(routes.talents.jobs['bookmark-by-id'](jobId));
			},
			getAllBookmarked: async (page = 1, limit = 9) => {
				return await axios.get(routes.talents.jobs.bookmark(), { params: { page, limit } });
			},
			findJobs: async (queryParams, page = 1, limit = 10) => {
				return await axios.get(routes.talents.jobs['search-jobs'](), { params: { page, limit, ...queryParams } });
			},
			appliedJobs: async (page = 1, limit = 100) => {
				return await axios.get(routes.talents.jobs['get-applied-jobs'](), { params: { page, limit } });
			},
			reportJob: async (jobId, postBody) => {
				return await axios.post(routes.talents.jobs['report-job'](jobId), postBody);
			},
			getJobTitles: async (page = 1, limit = 100) => {
				return await axios.get(routes.talents.jobs['job-titles'](), { params: { page, limit } });
			},
		},
		projects: {
			createProject: async (postBody) => {
				return await axios.post(routes.talents.projects['create-project'](), postBody);
			},
			updateProject: async (projectId, postBody) => {
				return await axios.patch(routes.talents.projects['project-by-id'](projectId), postBody);
			},
			getSingleProject: async (id) => {
				return await axios.get(routes.talents.projects['project-by-id'](id));
			},
			deleteProject: async (id) => {
				return await axios.delete(routes.talents.projects['delete-project'](id));
			},
			getAllProjects: async (page = 1, limit = 4) => {
				return await axios.get(routes.talents.projects['create-project'](), { params: { page, limit } });
			},
		},
		conversations: {
			getConversations: async (pageNumber = 1, pageSize = 14) => {
				return await axios.get(routes.talents.conversations['get-conversations'](), { params: { pageNumber, pageSize } });
			},
		},
	},
	freelancers: {
		createFeedback: async (postBody) => {
			return await axios.post(routes.freelancers.feedback(), postBody);
		},
		refer: {
			referTalent: async (postBody) => {
				return await axios.post(routes.freelancers.refer(), postBody);
			},
		},
		getBadges: async (id) => {
			return await axios.get(routes.freelancers['get-badges'](id));
		},
		profile: {
			get: async () => {
				return await axios.get(routes.freelancers.profile['get-details']());
			},
			update: async (postBody) => {
				return await axios.put(routes.freelancers.url(), postBody);
			},
			completeStatus: async () => {
				return await axios.get(routes.freelancers.profile['get-complete']());
			},
			uploadImage: async (postBody) => {
				return await axios.post(routes.freelancers.profile['upload-image'](), postBody);
			},
			addCertificate: async (postBody) => {
				return await axios.post(routes.freelancers.profile['add-certificate'](), postBody);
			},
			getCertificates: async (page = 1, limit = 100) => {
				return await axios.get(routes.freelancers.profile['add-certificate'](), { params: { page, limit } });
			},
			deleteCertificate: async (certId) => {
				return await axios.delete(routes.freelancers.profile['delete-certificate'](certId));
			},
		},
		jobs: {
			job: async (jobId) => {
				return await axios.get(routes.freelancers.jobs.id(jobId));
			},
			recommended: async (page = 1, limit = 10) => {
				return await axios.get(routes.freelancers.jobs.recommended(), { params: { page, limit } });
			},
			applyToJob: async (jobId, postBody) => {
				return await axios.post(routes.freelancers.jobs['apply-to-job'](jobId), postBody);
			},
			applyToJobQuestions: async (jobId, postBody) => {
				return await axios.patch(routes.freelancers.jobs['apply-to-job'](jobId), postBody);
			},
			declineApplyToJob: async (applicationId) => {
				return await axios.delete(routes.freelancers.jobs['decline-apply-to-job'](applicationId));
			},
			addExperience: async (postBody) => {
				return await axios.post(routes.freelancers.jobs['add-experience'](), postBody);
			},
			deleteExperience: async (id) => {
				return await axios.delete(routes.freelancers.jobs['delete-experience'](id));
			},
			addEducation: async (postBody) => {
				return await axios.post(routes.freelancers.jobs['add-education'](), postBody);
			},
			deleteEducation: async (id) => {
				return await axios.delete(routes.freelancers.jobs['delete-education'](id));
			},
			addToBookmark: async (jobId) => {
				return await axios.post(routes.freelancers.jobs['bookmark-by-id'](jobId));
			},
			removeFromBookmark: async (jobId) => {
				return await axios.delete(routes.freelancers.jobs['bookmark-by-id'](jobId));
			},
			getAllBookmarked: async (page = 1, limit = 9) => {
				return await axios.get(routes.freelancers.jobs.bookmark(), { params: { page, limit } });
			},
			findJobs: async (queryParams, page = 1, limit = 10) => {
				return await axios.get(routes.freelancers.jobs['search-jobs'](), { params: { page, limit, ...queryParams } });
			},
			appliedJobs: async (page = 1, limit = 100) => {
				return await axios.get(routes.freelancers.jobs['get-applied-jobs'](), { params: { page, limit } });
			},
			getJobTitles: async (page = 1, limit = 100) => {
				return await axios.get(routes.freelancers.jobs['job-titles'](), { params: { page, limit } });
			},
		},
		projects: {
			createProject: async (postBody) => {
				return await axios.post(routes.freelancers.projects['create-project'](), postBody);
			},
			updateProject: async (projectId, postBody) => {
				return await axios.patch(routes.freelancers.projects['project-by-id'](projectId), postBody);
			},
			getSingleProject: async (id) => {
				return await axios.get(routes.freelancers.projects['project-by-id'](id));
			},
			deleteProject: async (id) => {
				return await axios.delete(routes.freelancers.projects['delete-project'](id));
			},
			getAllProjects: async (page = 1, limit = 4) => {
				return await axios.get(routes.freelancers.projects['create-project'](), { params: { page, limit } });
			},
		},
		conversations: {
			getConversations: async (pageNumber = 1, pageSize = 14) => {
				return await axios.get(routes.freelancers.conversations['get-conversations'](), { params: { pageNumber, pageSize } });
			},
		},
	},
	admin: {
		skills: {
			createSkill: async (postBody) => {
				return await axios.post(routes.admin.skills.skill(), postBody);
			},
			getAllSkills: async (title = '', page = 1, limit = 1000) => {
				return await axios.get(routes.admin.skills.skill(), { params: { page, limit, ...title } });
			},
		},
	},
};

export default api;
