const baseUrl = process.env.REACT_APP_FRONTERS_BASE_URL;
const newFeatures = process.env.REACT_APP_FRONTERS_ADMIN_BASE_URL;

const authUrl = `${baseUrl}/auth`;

const routes = {
	register: (userType, rest) => `${authUrl}/${userType}/signup/${rest ? rest : ''}`,
	'google-register': (userType) => `${authUrl}/${userType}/google`,
	'facebook-register': (userType) => `${authUrl}/${userType}/facebook`,
	login: (userType) => `${authUrl}/${userType}/login`,
	'google-login': () => `${authUrl}/generic/google`,
	'facebook-login': () => `${authUrl}/generic/facebook`,
	'parse-cv': () => `${authUrl}/parse-cv`,
	'refresh-token': () => `${baseUrl}/auth/refresh-token`,
	'verify-account': () => `${baseUrl}/auth/verify-email-token`,
	're-verify-account': () => `${baseUrl}/auth/resend-email-token`,
	'contact-us': () => `${baseUrl}/frontters/contact-us`,
	'advertise-with-us': () => `${baseUrl}/frontters/advertisers-contact-us`,
	'get-users': () => `${baseUrl}/frontters/users`,
	'reset-password-request': () => `${authUrl}/reset-password-request`,
	'reset-password': () => `${authUrl}/reset-password`,
	'send-message': () => `${newFeatures}/conversations`,
	'get-messages': (id) => `${newFeatures}/conversations/messages/${id}`,
	'custom-messages': () => `${newFeatures}/conversations/custom-messages`,

	subscriptions: {
		subscriptions: () => `${newFeatures}/subscriptions/plans`,
		'get-single-subscription': (id) => `${routes.subscriptions.subscriptions()}/${id}`,
	},
	adverts: {
		'get-rooms': () => `${newFeatures}/advert/rooms`,
		'get-spaces': (roomId) => `${newFeatures}/advert/rooms/${roomId}/spaces`,
	},

	recruiter: {
		url: () => `${baseUrl}/recruiters`,
		jobs: () => `${routes.recruiter.url()}/jobs`,
		'job-id': (id) => `${routes.recruiter.url()}/jobs/${id}`,
		'company-logo': () => `${routes.recruiter.url()}/avatar`,
		'company-cac': () => `${routes.recruiter.url()}/cac-upload`,
		'find-talents': () => `${routes.recruiter.url()}/talents/find/`,
		'get-talent': (id) => `${routes.recruiter.url()}/talents/${id}`,
		'get-talents-project': (id) => `${routes.recruiter.url()}/talents/projects/${id}`,
		'get-talents-single-project': (projectId) => `${routes.recruiter.url()}/talents/projects/details/${projectId}`,
		'get-job-titles': () => `${routes.recruiter.url()}/jobs/job-titles`,
		'jobs-stats': () => `${routes.recruiter.jobs()}/stats`,
		'promote-job': (id) => `${newFeatures}/recruiters/jobs/promote/${id}`,
		'get-applied-jobs': () => `${routes.recruiter.jobs()}/applied`,
		'get-job-applicants': (id) => `${routes.recruiter.jobs()}/${id}/applicants`,
		'hire-talent': (id) => `${routes.recruiter.jobs()}/${id}/hire`,
		'get-job-hire-subscription': (id) => `${routes.recruiter.jobs()}/hire/${id}`,
		'initiate-payment-for-job': (id) => `${newFeatures}/recruiters/initiate-job-subscription/${id}`,
		'get-recommended-talents': (jobId) => `${routes.recruiter.jobs()}/recommended-talents/${jobId}`,
		profile: {
			'get-details': () => `${routes.recruiter.url()}`,
			'upload-image': () => `${routes.recruiter.url()}/profile-avatar`,
			'upload-company-image': () => `${routes.recruiter.url()}/logo-avatar`,
		},
		conversations: {
			'initiate-conversation': () => `${newFeatures}/conversations`,
			'get-conversations': () => `${newFeatures}/conversations/recruiter`,
		},
	},
	talents: {
		url: () => `${baseUrl}/talents`,
		feedback: () => `${routes.talents.url()}/feedback`,
		refer: () => `${routes.talents.url()}/refer`,
		'get-badges': (id) => `${newFeatures}/badges/awards/talents/${id}`,
		'apply-coupon': (code) => `${newFeatures}/talents/apply-promo-code/${code}`,
		profile: {
			'get-details': () => `${routes.talents.url()}`,
			'get-complete': () => `${routes.talents.url()}/completeness`,
			'upload-image': () => `${routes.talents.url()}/avatar`,
			'add-certificate': () => `${routes.talents.url()}/certs`,
			'get-certificates': (certId) => `${routes.talents.url()}/certs/${certId}`,
			'delete-certificate': (certId) => `${routes.talents.url()}/certs/${certId}`,
		},
		jobs: {
			id: (jobId) => `${routes.talents.url()}/jobs/${jobId}`,
			recommended: () => `${routes.talents.url()}/jobs/recommended`,
			'apply-to-job': (jobId) => `${routes.talents.url()}/jobs/${jobId}`,
			'decline-apply-to-job': (applicationId$) => `${routes.talents.url()}/jobs/applied/${applicationId$}`,
			'add-experience': () => `${routes.talents.url()}/experience`,
			'delete-experience': (experienceId) => `${routes.talents.url()}/experience/${experienceId}`,
			'add-education': () => `${routes.talents.url()}/education`,
			'delete-education': (educationId) => `${routes.talents.url()}/education/${educationId}`,
			bookmark: () => `${routes.talents.url()}/jobs/favorites`,
			'bookmark-by-id': (jobId) => `${routes.talents.url()}/jobs/favorites/${jobId}`,
			'search-jobs': () => `${routes.talents.url()}/jobs`,
			'get-applied-jobs': () => `${routes.talents.url()}/jobs/applied`,
			'report-job': (jobId) => `${routes.talents.url()}/jobs/report/${jobId}`,
			'job-titles': () => `${baseUrl}/jobs/job-titles`,
		},
		projects: {
			'create-project': () => `${routes.talents.url()}/projects`,
			'delete-project': (projectId) => `${routes.talents.url()}/projects/${projectId}`,
			'project-by-id': (projectId) => `${routes.talents.url()}/projects/${projectId}`,
		},
		conversations: {
			'get-conversations': () => `${newFeatures}/conversations/talent`,
		},
		subscriptions: {
			'initiate-subscription': () => `${newFeatures}/talents/initiate-subscription`,
			'get-subscription': () => `${newFeatures}/talents/subscriptions`,
			'verify-subscription': (id) => `${newFeatures}/talents/subscriptions/${id}`,
		},
	},
	freelancers: {
		feedback: () => `${baseUrl}/freelancers/feedback`,
		url: () => `${baseUrl}/freelancers/talents`,
		refer: () => `${routes.freelancers.url()}/refer`,
		'get-badges': (id) => `${newFeatures}/badges/awards/talents/${id}`,
		profile: {
			'get-details': () => `${routes.freelancers.url()}`,
			'get-complete': () => `${routes.freelancers.url()}/completeness`,
			'upload-image': () => `${routes.freelancers.url()}/avatar`,
			'add-certificate': () => `${routes.freelancers.url()}/certs`,
			'get-certificates': (certId) => `${routes.freelancers.url()}/certs/${certId}`,
			'delete-certificate': (certId) => `${routes.freelancers.url()}/certs/${certId}`,
		},
		jobs: {
			id: (jobId) => `${routes.freelancers.url()}/jobs/${jobId}`,
			recommended: () => `${routes.freelancers.url()}/jobs/recommended`,
			'apply-to-job': (jobId) => `${routes.freelancers.url()}/jobs/${jobId}`,
			'decline-apply-to-job': (applicationId$) => `${routes.freelancers.url()}/jobs/applied/${applicationId$}`,
			'add-experience': () => `${routes.freelancers.url()}/experience`,
			'delete-experience': (experienceId) => `${routes.freelancers.url()}/experience/${experienceId}`,
			'add-education': () => `${routes.freelancers.url()}/education`,
			'delete-education': (educationId) => `${routes.freelancers.url()}/education/${educationId}`,
			bookmark: () => `${routes.freelancers.url()}/jobs/favorites`,
			'bookmark-by-id': (jobId) => `${routes.freelancers.url()}/jobs/favorites/${jobId}`,
			'search-jobs': () => `${routes.freelancers.url()}/jobs`,
			'get-applied-jobs': () => `${routes.freelancers.url()}/jobs/applied`,
			'job-titles': () => `${baseUrl}/jobs/job-titles`,
		},
		projects: {
			'create-project': () => `${routes.freelancers.url()}/projects`,
			'delete-project': (projectId) => `${routes.freelancers.url()}/projects/${projectId}`,
			'project-by-id': (projectId) => `${baseUrl}/freelancers/projects/${projectId}`,
		},
		conversations: {
			'get-conversations': () => `${newFeatures}/conversations/talent`,
		},
	},
	admin: {
		url: () => `${baseUrl}/admins`,
		skills: {
			skill: () => `${routes.admin.url()}/skills`,
		},
	},
};

export default routes;
