import { useState, useRef, useEffect } from 'react';
import Navbar from 'views/_shared/navbar/Navbar';
import './css/EditProject.style.sass';
import { navbarComp } from '../../dashboard/talent/navbar-components/navbar-comp';
import { ImageUploadComponent, TextUploadComponent, VideoUploadComponent } from './file-upload-component/FileUploadComponent';
import Footer from 'views/_shared/footer/Footer';
import api from 'services/api';
import service from 'services';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';

function EditProject() {
	const userType = service.getUserType();
	const { projectId } = useParams();
	const [currentStage, setCurrentStage] = useState(1);
	const [projectData, setProjectData] = useState({
		project_title: '',
		project_hash_tags: [],
		project_images: [],
		project_description: '',
		project_videos: [],
		project_skills: [],
		project_cover_image: '',
		project_source_file: [],
		project_link: '',
		project_status: '',
		project_cover_image_tbu: '',
		project_videos_tbu: [],
		project_images_tbu: [],
		project_source_file_tbu: [],
	});
	const [error, setError] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [publishing, setPublishing] = useState(false);
	const [drafting, setDrafting] = useState(false);
	const [isLoadingDraft, setIsLoadingDraft] = useState(false);
	const [isLoadingPublish, setIsLoadingPublish] = useState(false);

	useEffect(() => {
		if (userType === 'talent') {
			api.talents.projects.getSingleProject(projectId).then((response) => {
				const project = response.data.result;
				setProjectData((prev) => ({
					...prev,
					project_title: project.project_title,
					project_hash_tags: project.project_hash_tags,
					project_images: project.project_images,
					project_description: project?.project_description,
					project_videos: project.project_videos,
					project_skills: project.project_skills,
					project_cover_image: project.project_cover_image,
					project_source_file: project.project_source_files,
					project_link: project.project_link,
					project_status: '',
				}));
			});
		} else {
			api.freelancers.projects.getSingleProject(projectId).then((response) => {
				const project = response.data.result;
				setProjectData((prev) => ({
					...prev,
					project_title: project.project_title,
					project_hash_tags: project.project_hash_tags,
					project_images: project.project_images,
					project_description: project?.project_description,
					project_videos: project.project_videos,
					project_skills: project.project_skills,
					project_cover_image: project.project_cover_image,
					project_source_file: project.project_source_files,
					project_link: project.project_link,
					project_status: '',
				}));
			});
		}
	}, [projectId, userType]);

	useEffect(() => {
		if (projectData.project_images.length > 0 && projectData.project_cover_image === '') {
			setProjectData((prevData) => ({
				...prevData,
				project_cover_image: prevData.project_images[0],
			}));
		}
		if (projectData.project_images_tbu.length > 0 && projectData.project_cover_image_tbu === '') {
			setProjectData((prevData) => ({
				...prevData,
				project_cover_image_tbu: prevData.project_images_tbu[0],
			}));
		}
	}, [projectData]);

	const fileInputRef = useRef(null);

	const handleFileSelection = () => {
		fileInputRef.current.click();
	};

	const coverImageRef = useRef(null);

	const handleCoverSelection = () => {
		coverImageRef.current.click();
	};

	const navigate = useNavigate();

	const handleNextStage = () => {
		if (!projectData.project_description) {
			alert('Please provide a project description.');
			return;
		}

		if (projectData.project_images.length === 0) {
			alert('Please upload at least one project image.');
			return;
		}

		if (projectData.project_skills.length < 2) {
			alert('Please select at least two project skills.');
			return;
		}
		setCurrentStage(currentStage + 1);
		setIsModalOpen(true);
	};

	const handlePreviousStage = () => {
		setCurrentStage(currentStage - 1);
		setIsModalOpen(false);
	};

	const handleProjectTitleChange = (event) => {
		const value = event.target.value;
		setProjectData((prevState) => ({
			...prevState,
			project_title: value,
		}));
	};

	const handleProjectSourceFile = (event) => {
		const files = event.target.files;
		setProjectData((prevState) => ({
			...prevState,
			project_source_file_tbu: [...prevState.project_source_file_tbu, ...Array.from(files)],
		}));
	};

	function handleChangeCoverImage(event) {
		const newCoverImageFile = event.target.files[0];
		setProjectData((prevData) => ({
			...prevData,
			project_cover_image_tbu: newCoverImageFile,
		}));
	}

	const deleteSourceFile = (index) => {
		setProjectData((prevState) => {
			const updatedSourceFiles = [...prevState.project_source_file];
			updatedSourceFiles.splice(index, 1);
			return {
				...prevState,
				project_source_file: updatedSourceFiles,
			};
		});
	};

	const handleProjectLink = (event) => {
		const value = event.target.value;
		setProjectData((prevState) => ({
			...prevState,
			project_link: value,
		}));
	};

	const handleHashtagsChange = (event) => {
		const hashtag = event.target.value;
		setProjectData((prevState) => ({
			...prevState,
			project_hash_tags: hashtag.split(',').map((tag) => tag.trim()),
		}));
	};

	const handleImageUpload = (images) => {
		setProjectData((prevState) => ({
			...prevState,
			project_images_tbu: [...prevState.project_images_tbu, ...images],
		}));
	};

	const handleRemoveImage = (_id) => {
		setProjectData((prevState) => {
			const newImages = prevState.project_images.filter((image) => image._id !== _id);
			return { ...prevState, project_images: newImages };
		});
	};

	const handleTextUpload = (text) => {
		setProjectData((prevState) => ({
			...prevState,
			project_description: text,
		}));
	};

	const handleVideoUpload = (videos) => {
		setProjectData((prevState) => ({
			...prevState,
			project_videos_tbu: [...prevState.project_videos_tbu, ...videos],
		}));
	};

	const handleRemoveVideo = (_id) => {
		setProjectData((prevState) => {
			const newVideos = prevState.project_videos.filter((video) => video._id !== _id);
			return { ...prevState, project_videos: newVideos };
		});
	};

	const handleSkillClick = (skill) => {
		setProjectData((prevState) => {
			const projectSkills = prevState.project_skills;

			if (projectSkills.includes(skill)) {
				return {
					...prevState,
					project_skills: projectSkills.filter((selected) => selected !== skill),
				};
			} else {
				return {
					...prevState,
					project_skills: [...projectSkills, skill],
				};
			}
		});
	};

	const renderModalContent = () => {
		return (
			<div id="modal_component" style={{ height: '90%', overflow: 'auto' }}>
				<section className="d-flex justify-content-between bg-dark p-1">
					<img src="/img/svg/profile.svg" alt="" style={{ width: '20px' }} />
					<p style={{ color: 'white' }}>PUBLISH</p>
					<img src="/img/svg/X.svg" alt="" style={{ width: '20px', cursor: 'pointer' }} onClick={handlePreviousStage} />
				</section>
				<section className="project_details row">
					{/* cover */}
					<div className="left col-lg-4" style={{ width: '30%' }}>
						<p className="fw-semibold  mb-2" style={{ fontSize: '18px' }}>
							Project Cover
						</p>
						{projectData.project_cover_image_tbu ? (
							<img src={URL.createObjectURL(projectData.project_cover_image_tbu)} alt="cover_image" style={{ width: 'auto', height: '150px' }} className="cover" />
						) : projectData.project_images.length > 0 ? (
							<img src={projectData.project_images[0] instanceof File ? URL.createObjectURL(projectData.project_images[0]) : projectData.project_images[0].href_link} alt="cover_image" style={{ width: 'auto', height: '150px' }} className="cover" />
						) : (
							<p>No cover image selected.</p>
						)}
						<button type="button" className="below_attach mt-5" style={{ margin: '10px 0' }} onClick={handleCoverSelection}>
							Attach Cover image <img src="/img/svg/attach.svg" alt="" style={{ width: '20px' }} />
						</button>
						<input type="file" ref={coverImageRef} accept="image/png, image/jpg, image/jpeg" onChange={handleChangeCoverImage} className="d-none" />
					</div>
					{/* details */}
					<div className="d-flex flex-column gap-4 right col-lg-7" style={{ width: '70%' }}>
						<div className="d-flex flex-column gap-2">
							<label>Project Title</label>
							<input type="text" value={projectData.project_title} onChange={handleProjectTitleChange} placeholder="Your Title" className="p-3" />
							<p style={{ color: 'red', fontSize: '16px', fontStyle: 'italic' }}>{error}</p>
						</div>
						<div className="d-flex flex-column gap-2">
							<label>Hashtags</label>
							<textarea value={projectData.project_hash_tags} onChange={handleHashtagsChange} rows={8} style={{ width: '100%' }} placeholder="eg... #react,#figma" className="p-3" />
							<p style={{ color: 'red', fontSize: '16px', fontStyle: 'italic' }}>{error}</p>
						</div>
					</div>
				</section>

				<div className="d-flex" style={{ margin: '0 auto' }}>
					<p className="text-center" style={{ color: 'red', fontSize: '16px', fontStyle: 'italic' }}>
						{error}
					</p>
				</div>

				<section className="buttons mt-5 mb-3 d-flex gap-2" style={{ marginLeft: 'auto', marginRight: '20px' }}>
					<button onClick={handlePreviousStage}>Cancel</button>
					<Button text={<LoaderButton isLoading={isLoadingDraft} text="Draft" />} onClick={handledraftProject} disabled={isLoadingDraft || isLoadingPublish} />

					<Button text={<LoaderButton isLoading={isLoadingPublish} text={error ? 'Retry' : 'Publish'} />} onClick={handlePublishProject} disabled={isLoadingDraft || isLoadingPublish} />
				</section>
			</div>
		);
	};

	const renderStage = () => {
		return (
			<div className="d-flex flex-column gap-4 mb-4" style={{ width: '100%' }}>
				{<ImageUploadComponent onUpload={handleImageUpload} fetchedImages={[...projectData.project_images, ...projectData.project_images_tbu]} handleRemoveImage={handleRemoveImage} />}
				{<TextUploadComponent onUpload={handleTextUpload} uploadedText={projectData.project_description} />}
				{<VideoUploadComponent onUpload={handleVideoUpload} fetchedVideos={[...projectData.project_videos, ...projectData.project_videos_tbu]} handleRemoveVideo={handleRemoveVideo} />}
			</div>
		);
	};

	function publishProject(status, updatedProjectData) {
		const requiredFields = ['project_title', 'project_hash_tags', 'project_description', 'project_images', 'project_skills'];

		let hasError = false;

		for (const field of requiredFields) {
			if (field === 'project_images' && projectData[field].length === 0) {
				setError('Please upload at least one project image.');
				hasError = true;
			} else if (field === 'project_skills' && projectData[field].length < 2) {
				setError('Please select at least two project skills.');
				hasError = true;
			} else if (field === 'project_hash_tags' && projectData[field].length === 0) {
				setError('Please add at least one project hash tag.');
				hasError = true;
			} else if (!projectData[field]) {
				setError(`Please fill in the ${field.replace('_', ' ')} field.`);
				hasError = true;
			}

			if (hasError) {
				setTimeout(() => {
					setError('');
				}, 3000);
				if (status === 'published') {
					setIsLoadingPublish(false);
				} else {
					setIsLoadingDraft(false);
				}
				return;
			}
		}

		const handleSuccess = () => {
			setPublishing(true);
			navigate('/dashboard/projects');
		};

		const handleError = (response) => {
			const check = response.data.status;
			if (check === false) {
				console.log('error creating project', response);
			}
		};

		const formData = new FormData();
		formData.append('project_title', updatedProjectData.project_title);
		formData.append('project_description', updatedProjectData.project_description);
		formData.append('project_cover_image', JSON.stringify(updatedProjectData.project_cover_image));
		formData.append('project_link', updatedProjectData.project_link);
		formData.append('project_status', status);
		// this part sends the original file back to the backend for the backend to parse
		formData.append('project_images', JSON.stringify(updatedProjectData.project_images));
		formData.append('project_videos', JSON.stringify(updatedProjectData.project_videos));
		formData.append('project_source_files', JSON.stringify(updatedProjectData.project_source_file));

		updatedProjectData.project_skills.forEach((skill) => {
			formData.append('project_skills', skill);
		});

		formData.append('project_cover_image_tbu', updatedProjectData.project_cover_image_tbu);

		// new source file to replace
		updatedProjectData.project_source_file_tbu.forEach((source) => {
			formData.append('project_source_files_tbu', source);
		});

		if (Array.isArray(updatedProjectData.project_hash_tags)) {
			updatedProjectData.project_hash_tags.forEach((hash) => {
				formData.append('project_hash_tags[]', hash);
			});
		}

		// new images and videos files
		for (let i = 0; i < updatedProjectData.project_images_tbu.length; i++) {
			formData.append('project_images_tbu', updatedProjectData.project_images_tbu[i]);
		}
		for (let i = 0; i < updatedProjectData.project_videos_tbu.length; i++) {
			formData.append('project_videos_tbu', updatedProjectData.project_videos_tbu[i]);
		}

		if (userType === 'talent') {
			api.talents.projects
				.updateProject(projectId, formData)
				.then(handleSuccess)
				.catch(handleError)
				.finally(() => {
					if (status === 'published') {
						setIsLoadingPublish(false);
					} else {
						setIsLoadingDraft(false);
					}
				});
		} else {
			api.freelancers.projects
				.updateProject(projectId, formData)
				.then(handleSuccess)
				.catch(handleError)
				.finally(() => {
					if (status === 'published') {
						setIsLoadingPublish(false);
					} else {
						setIsLoadingDraft(false);
					}
				});
		}
	}

	const handlePublishProject = () => {
		setIsLoadingPublish(true);
		setPublishing(true);
		setDrafting(false);

		if (error) {
			setPublishing(false);
			setIsLoadingPublish(false);
			return;
		}

		setProjectData((prev) => {
			const updatedProjectData = { ...prev, project_status: 'published' };
			publishProject('published', updatedProjectData);
			return updatedProjectData;
		});
	};

	const handledraftProject = () => {
		setIsLoadingDraft(true);
		setPublishing(publishing);
		setDrafting(!drafting);

		setProjectData((prev) => {
			const updatedProjectData = { ...prev, project_status: 'drafted' };
			publishProject('drafted', updatedProjectData);
			return updatedProjectData;
		});
	};

	return (
		<main>
			<Navbar {...navbarComp} />

			<section className="container" id="EditProject_Main_Container">
				<h3>You are now Editing your project</h3>

				<section className="Edit_Project_Card">
					<h3 id="title" className="d-flex gap-1 align-items-center">
						<img src="/img/svg/book.svg" alt="book" style={{ width: '20px' }} /> What would you love to post today
					</h3>

					<div className="render">{renderStage()}</div>

					<div className="below">
						<input type="file" multiple accept=".pdf,.doc,.docx" ref={fileInputRef} onChange={handleProjectSourceFile} style={{ display: 'none' }} />
						<button type="button" className="below_attach" onClick={handleFileSelection}>
							Attach Source File <img src="/img/svg/attach.svg" alt="" style={{ width: '20px' }} />
						</button>
						<div>
							{projectData.project_source_file?.map((file, index) => {
								const getFileName = (file) => {
									return file.name || file.file_name || 'Unknown File';
								};
								return (
									<div key={index}>
										{getFileName(file)}
										<button onClick={deleteSourceFile} style={{ textDecoration: 'underline', color: 'red' }}>
											remove
										</button>
									</div>
								);
							})}
						</div>
						<div>
							{projectData.project_source_file_tbu?.map((file, index) => {
								const getFileName = (file) => {
									return file.name || file.file_name || 'Unknown File';
								};
								return (
									<div key={index}>
										{getFileName(file)}
										<button onClick={deleteSourceFile} style={{ textDecoration: 'underline', color: 'red' }}>
											remove
										</button>
									</div>
								);
							})}
						</div>
						<p>Add files like fonts, illustrations, photos, or templates to this project</p>
						<h3>OR</h3>
						<input type="text" name="link" id="link" placeholder="Enter link to file" value={projectData.project_link} onChange={handleProjectLink} />
						{/* skills */}
						<h3 className="d-flex gap-1 align-items-center">
							<img src="/img/svg/toolkit.svg" alt="tools" style={{ width: '20px' }} /> What skill is related to this project
						</h3>
						<div id="related_skill">
							{['photoshop', 'UI/UX', 'Frontend dev', 'backend dev', 'cloud computing', 'copy writing', 'instructor', 'copy writing', 'DSA', 'computer engineering'].map((skill, index) => {
								const isSelected = projectData.project_skills.includes(skill);
								const skillStyle = {
									color: isSelected ? 'white' : '',
									backgroundColor: isSelected ? 'black' : 'transparent',
									borderColor: isSelected ? 'transparent' : '',
								};
								return (
									<div id={'skill'} key={index} style={skillStyle} onClick={() => handleSkillClick(skill)} className="position-relative">
										<p>{skill}</p>
										{isSelected && <i className="fa-solid fa-xmark"></i>}
									</div>
								);
							})}
						</div>
						<button type="submit" onClick={handleNextStage} className="btn-solid">
							Next ➜
						</button>
					</div>
				</section>
				{isModalOpen && (
					<section className="project_modal">
						<>{renderModalContent()}</>
					</section>
				)}
			</section>
			<Footer />
		</main>
	);
}

export default EditProject;
