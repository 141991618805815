import Button from 'components/buttons/Button';
import Tab from 'components/buttons/tabs/Tabs';
import Modal from 'components/modals/Modal';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import './css/SectionC.style.sass';
import './css/SectionC.css';

import { handleModal } from './modal/Modal';
import { Country, State } from 'country-state-city';

function SectionC(props) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const [jobType, setJobType] = useState(0);
	const [contractType, setContractType] = useState(0);
	const [jobSeekerType, setJobSeekerType] = useState(0);
	const [gender, setGender] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [showCountry, setShowCountry] = useState(false);

	const currencies = [
		{ name: 'United States Dollar', symbol: '$', code: 'USD' },
		{ name: 'Euro', symbol: '€', code: 'EUR' },
		{ name: 'Japanese Yen', symbol: '¥', code: 'JPY' },
		{ name: 'British Pound Sterling', symbol: '£', code: 'GBP' },
		{ name: 'Swiss Franc', symbol: 'CHF', code: 'CHF' },
		{ name: 'Canadian Dollar', symbol: 'C$', code: 'CAD' },
		{ name: 'Australian Dollar', symbol: 'A$', code: 'AUD' },
		{ name: 'Indian Rupee', symbol: '₹', code: 'INR' },
		{ name: 'Chinese Yuan', symbol: '¥', code: 'CNY' },
		{ name: 'South African Rand', symbol: 'R', code: 'ZAR' },
		{ name: 'United Arab Emirates Dirham', symbol: 'د.إ', code: 'AED' },
		{ name: 'Singapore Dollar', symbol: 'S$', code: 'SGD' },
		{ name: 'Mexican Peso', symbol: 'Mex$', code: 'MXN' },
		{ name: 'Brazilian Real', symbol: 'R$', code: 'BRL' },
		{ name: 'Thai Baht', symbol: '฿', code: 'THB' },
		{ name: 'Hong Kong Dollar', symbol: 'HK$', code: 'HKD' },
		{ name: 'New Zealand Dollar', symbol: 'NZ$', code: 'NZD' },
		{ name: 'Swedish Krona', symbol: 'kr', code: 'SEK' },
		{ name: 'Norwegian Krone', symbol: 'kr', code: 'NOK' },
		{ name: 'Danish Krone', symbol: 'kr', code: 'DKK' },
		{ name: 'Russian Ruble', symbol: '₽', code: 'RUB' },
		{ name: 'Nigerian Naira', symbol: '₦', code: 'NGN' },
		// Add more currencies as needed
	];
	// eslint-disable-next-line no-unused-vars
	const [countries, setCountries] = useState(Country.getAllCountries());
	const [statesOfSelectedCountry, setStatesOfSelectedCountry] = useState([]);

	const [form, setForm] = useState({
		country: '',
		state: '',
		required_years_of_experience: '',
		expected_pay: '',
		// job_email: '',
		currency: '',
		// receive_talent_by: '',
		job_type: '',
		contract_type: 'job-seeker',
		preferred_gender: 'male',
		job_seeker_type: '',
		world_wide: false,
	});

	const [formErrors, setFormErrors] = useState(form);

	const handleOnChange = (e) => {
		const attribute = e.target.name;
		const value = e.target.value;
		setForm({ ...form, [`${attribute}`]: value });
		setFormErrors({ ...formErrors, [`${attribute}`]: `` });
	};

	const handleCountryChange = (event) => {
		const selectedCountryName = event.target.value;
		const selectedCountry = countries.find((country) => country.name === selectedCountryName);
		if (selectedCountry) {
			setForm({ ...form, country: selectedCountry.name });
			// Update the states based on the selected country
			const states = State.getStatesOfCountry(selectedCountry.isoCode);
			setForm((prevForm) => ({ ...prevForm, state: '' })); // Reset the state when changing the country
			setStatesOfSelectedCountry(states);
		} else {
			setForm({ ...form, country: '', state: '' });
			setStatesOfSelectedCountry([]);
		}
	};

	function handleLocation(e) {
		const optionSelect = e.target.value;

		if (optionSelect === 'worldWide') {
			setForm((prevFilters) => ({
				...prevFilters,
				world_wide: true,
				country: null,
				state: null,
			}));
			setShowCountry(false);
		} else if (optionSelect === 'choose') {
			setShowCountry(true);

			setForm((prevFilters) => ({
				...prevFilters,
				world_wide: false,
			}));
		}
	}

	const handleJobTypeChange = (index) => {
		setJobType(index);
		setForm((prevForm) => ({
			...prevForm,
			job_type: ['remote', 'hybrid', 'onsite'][index],
		}));
	};

	function handleContractTypeChange(y) {
		setContractType(y);
		const selectedContractType = ['job-seeker', 'freelancer'][y];
		setForm((prevState) => ({
			...prevState,
			contract_type: selectedContractType,
		}));
		if (formErrors.contract_type) {
			setFormErrors((prevErrors) => ({
				...prevErrors,
				contract_type: '',
			}));
		}
	}

	const handleJobSeekerTypeChange = (index) => {
		setJobSeekerType(index);
		setForm((prevForm) => ({
			...prevForm,
			job_seeker_type: ['full-time', 'part-time'][index],
		}));
	};

	const handleGenderChange = (index) => {
		setGender(index);
		setForm((prevForm) => ({
			...prevForm,
			preferred_gender: ['male', 'female', 'any'][index],
		}));
	};

	const handleStateChange = (event) => {
		const selectedStateName = event.target.value;
		const selectedState = statesOfSelectedCountry.find((state) => state.name === selectedStateName);

		if (selectedState) {
			setForm({ ...form, state: selectedState.name });
		} else {
			setForm({ ...form, state: selectedStateName || 'No State' });
		}
	};

	const configFields = (form) => {
		form.job_type = ['remote', 'hybrid', 'onsite'][jobType];
		form.contract_type = ['job-seeker', 'freelancer'][contractType];
		form.job_seeker_type = ['full-time', 'part-time'][jobSeekerType];
		form.preferred_gender = ['male', 'female', 'any'][gender];

		const postBody = { ...form, ...props.postBody };

		postBody.required_years_of_experience = Number(postBody.required_years_of_experience);
		postBody.expected_pay = Number(postBody.expected_pay);

		postBody.location = { country: postBody.country, state: postBody.state };

		delete postBody.country;
		delete postBody.state;

		return postBody;
	};
	const hasErrors = () => {
		let hasLocation = form.world_wide || (form.country && form.state); // Ensure at least one is filled

		for (const field in form) {
			// Skip validation for country and state if world_wide is true
			if (form.world_wide && (field === 'country' || field === 'state')) {
				continue;
			}

			// Skip checking world_wide if both country and state are filled
			if ((field === 'world_wide' || field === 'country' || field === 'state') && hasLocation) {
				continue;
			}

			// If any other field is empty, trigger an error
			if (!form[field]) {
				const formElement = document.getElementById('section-c')?.elements;
				formElement?.[field]?.focus();
				formElement?.[field]?.scrollIntoView({ block: 'center' });

				setFormErrors((prevErrors) => ({
					...prevErrors,
					[field]: `${field} is required`,
				}));

				console.warn(`${field} field is required`);
				return true;
			}
		}

		// If neither world_wide nor (country + state) are filled, throw an error
		if (!hasLocation) {
			setFormErrors((prevErrors) => ({
				...prevErrors,
				location: 'Either select world wide or fill country & state',
			}));
			console.warn('Either select world wide or fill country & state');
			return true;
		}

		return false;
	};

	function doSubmit(callback, status = 'drafted') {
		form.status = status;
		const postBody = configFields(form);

		if (!hasErrors()) {
			setIsLoading(true);

			function successHandler() {
				callback();
			}

			function errorHandler(response) {
				console.error(response);
			}

			function onFinal() {
				setIsLoading(false);
			}

			api.recruiter.createJob(postBody).then(successHandler).catch(errorHandler).finally(onFinal);
		}
	}

	const callbackA = () => {
		navigate('/dashboard');
	};

	const callbackB = () => {
		hasErrors();
	};

	// const callbackC = () => {
	// 	navigate('/dashboard/search');
	// };

	const modalProps = {
		modalWidth: '640px',
		modalTarget: 'post-opportunity-submitted',
		modalTitle: 'opportunity post review',
	};

	return (
		<div id="JobFlow_SectionC_Main_Container" ref={top}>
			<div className="section" style={{ background: 'unset', padding: '55px 0' }}>
				<div className="container-01">
					<div className="con-title">
						<h1>We have 50+ Talents for this Opportunity</h1>

						<div className="d-lg-flex align-items-center gap-3">
							<p>Let's narrow it down, shall we?</p>
						</div>
					</div>

					<div className="con-form">
						<div className="form">
							<form id="section-c">
								<div className="d-lg-flex justify-content-between gap-4">
									<div className="control-form">
										<div className="d-flex gap-3 align-items-center ">
											<label>Contract Type</label>
										</div>
										<div className="d-flex align-items-center gap-1 mb-3">
											{['Job Seeker', 'Freelancer'].map((x, y) => (
												<Tab primaryOutline darkBg={form.contract_type === x.toLowerCase()} context={x} key={y} callback={() => handleContractTypeChange(y)} activeTab={contractType === y} />
											))}
										</div>
									</div>
								</div>
								{contractType === 0 && (
									<div>
										<div className="con-title">
											{/* <i className="fa-solid fa-location-dot"></i> */}
											<h2>What is the nature of the job?</h2>
										</div>

										<div className="d-lg-flex justify-content-between gap-4">
											<div className="control-form">
												<div className="d-lg-flex gap-2 mb-4">
													{['Remote', 'Hybrid', 'On-site'].map((x, y) => (
														<Tab key={y} context={x} primaryOutline callback={() => handleJobTypeChange(y)} activeTab={jobType === y} />
													))}
												</div>
											</div>
										</div>
									</div>
								)}

								{contractType === 0 && (
									<div>
										<div className="con-title">
											{/* <i className="fa-solid fa-location-dot"></i> */}
											<h2>Job Seeker type</h2>
										</div>

										<div className="d-lg-flex justify-content-between gap-4">
											<div className="control-form">
												<div className="d-lg-flex gap-2 mb-4">
													{['Full-Time', 'Part-time'].map((x, y) => (
														<Tab key={y} context={x} primaryOutline callback={() => handleJobSeekerTypeChange(y)} activeTab={jobSeekerType === y} />
													))}
												</div>
											</div>
										</div>
									</div>
								)}

								<div>
									<div className="con-title">
										{/* <i className="fa-solid fa-location-dot"></i> */}
										<h2>Gender</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-lg-flex gap-2 mb-4">
												{['Male', 'Female', 'Any'].map((x, y) => (
													<Tab key={y} context={x} primaryOutline callback={() => handleGenderChange(y)} activeTab={gender === y} />
												))}
											</div>
										</div>
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-location-dot"></i>
										<h2>Location</h2>
									</div>
									<div class="radio-button-container">
										<div class="radio-button">
											<input type="radio" class="radio-button__input" id="radio1" name="radio-group" value={'worldWide'} onChange={handleLocation} />
											<label class="radio-button__label" for="radio1">
												<span class="radio-button__custom"></span>
												Worldwide
											</label>
										</div>
										<div class="radio-button">
											<input type="radio" class="radio-button__input" id="radio2" name="radio-group" value={'choose'} onChange={handleLocation} />
											<label class="radio-button__label" for="radio2">
												<span class="radio-button__custom"></span>
												Choose specific location
											</label>
										</div>
									</div>
									{showCountry && (
										<div className="row flex-lg-row  flex-column   gap-4 my-4">
											<div className="col-lg-5">
												<div className="control-form">
													<div className="d-flex justify-content-between">
														<label>Country</label>

														<label className="error-msg mb-3">{formErrors.country}</label>
													</div>

													{/*<input type="text" placeholder="Enter Country" className={formErrors.country ? 'error' : ''} name="country" value={form.country} onChange={handleOnChange} />*/}

													<select className={formErrors.country ? 'error' : ''} name="country" value={form.country} onChange={handleCountryChange}>
														<option value="">Select country</option>
														{countries.map((country) => {
															return (
																<option key={country.name} value={country.name}>
																	{country.name}
																</option>
															);
														})}
													</select>
												</div>
											</div>
											<div className="col-lg-5">
												<div className="control-form">
													<div className="d-flex justify-content-between">
														<label>State</label>

														<label className="error-msg mb-3">{formErrors.state}</label>
													</div>

													<select className={formErrors.state ? 'error' : ''} name="state" value={form.state} onChange={handleStateChange} disabled={!form.country}>
														<option value="">Select state</option>
														{statesOfSelectedCountry.length === 0 ? (
															<option key={'no_state'} value="No State">
																No State
															</option>
														) : (
															statesOfSelectedCountry.map((state) => (
																<option key={state.isoCode} value={state.name}>
																	{state.name}
																</option>
															))
														)}
													</select>
												</div>
											</div>
										</div>
									)}
								</div>

								<div className="mt-5">
									<div className="justify-content-between  row flex-column gap-4">
										<div className="col-lg-6">
											<div className="control-form ">
												<div className="con-title  " style={{ flex: 1 }}>
													{/* <i className="fa-solid fa-location-dot"></i> */}
													<h2>Work Experience</h2>
												</div>
												<div className="d-flex justify-content-between">
													<label>Years of work experience</label>

													<label className="error-msg mb-3">{formErrors.required_years_of_experience}</label>
												</div>

												<input
													type="number"
													placeholder="How many years of experience"
													className={formErrors.required_years_of_experience ? 'error' : ''}
													name="required_years_of_experience"
													min={1}
													value={form.required_years_of_experience}
													onChange={handleOnChange}
												/>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="control-form col-lg-7">
												<div className="con-title " style={{ flex: 1 }}>
													{/* <i className="fa-solid fa-location-dot"></i> */}
													<h2>What is the expected pay ({`${form.contract_type === 'freelancer' ? 'hourly' : 'monthly'}`}) ?</h2>
												</div>

												<div className="d-lg-flex justify-content-between gap-4">
													<div className="control-form">
														<div className="d-flex justify-content-between">
															<label>Pay range / ({`${form.contract_type === 'freelancer' ? 'hour' : 'month'}`})</label>

															<label className="error-msg mb-3">{formErrors.expected_pay}</label>
														</div>
														<div>
															<div className="input-group mb-3">
																<select className="form-select" aria-label="Default select example" style={{ flex: 1 }} name="currency" value={form.currency} onChange={handleOnChange}>
																	<option defaultValue>Currency</option>
																	{currencies.map((currency) => {
																		return (
																			<option key={currency.code} value={currency.code}>
																				{currency.name} ({currency.symbol})
																			</option>
																		);
																	})}
																</select>
																<input
																	type="number"
																	className={formErrors.expected_pay ? 'error form-control' : ' form-control'}
																	placeholder="Enter expected pay"
																	min={1}
																	name="expected_pay"
																	value={form.expected_pay}
																	onChange={handleOnChange}
																	style={{ flex: 5 }}
																/>
															</div>
														</div>

														{/* <input type="number" placeholder="Enter expected pay" className={formErrors.expected_pay ? 'error form-control' : ' form-control'} min={1} name="expected_pay" value={form.expected_pay} onChange={handleOnChange} /> */}
													</div>

													{/* <i className="control-form"></i> */}
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* <div>
									<div className="con-title">
										<h2>How would you like to receive your applicants?</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4 mb-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Receive talents by</label>

												<label className="error-msg mb-3">{formErrors.receive_talent_by}</label>
											</div>

											<select className={formErrors.receive_talent_by ? 'error' : ''} name="receive_talent_by" value={form.receive_talent_by} onChange={handleOnChange}>
												<option value="">Select how you'd receive talents</option>
												<option value="email">Email</option>
												<option value="phone">Phone</option>
											</select>
										</div>

										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Email Address</label>

												<label className="error-msg mb-3">{formErrors.job_email}</label>
											</div>

											<input type="text" placeholder="key@test.com" className={formErrors.job_email ? 'error' : ''} name="job_email" value={form.job_email} onChange={handleOnChange} />
										</div>
									</div>
								</div> */}

								<div className="control-form d-flex flex-column flex-lg-row gap-3">
									<div className="mb-5 mb-lg-0">
										<Button
											text={
												<>
													<i className="fa-solid fa-arrow-left"></i>
													<h1>One Step back</h1>
												</>
											}
											primaryNull
											onClick={props.prev}
										/>
									</div>
									<Button text={<h1>Save for Later</h1>} secondaryNull disabled={isLoading} onClick={() => doSubmit(callbackA)} />

									{!form.required_years_of_experience || !form.expected_pay || isLoading ? (
										<Button text={<h1>Post Opportunity</h1>} primaryNull disabled={isLoading} onClick={callbackB} />
									) : (
										<Button text={<h1>Post Opportunity</h1>} primaryNull disabled={isLoading} {...handleModal()} />
									)}

									{/* <Button
										text={
											<>
												<h1>Find your Talents now</h1>
												<i className="fa-solid fa-arrow-right"></i>
											</>
										}
										disabled={isLoading}
										onClick={() => doSubmit(callbackC)}
									/> */}
								</div>
							</form>
						</div>
					</div>
				</div>

				<Modal
					{...handleModal({
						...props.postBody,
						...form,
						callback: () => doSubmit(() => {}, 'published'),
					})}
				/>

				<Modal
					{...{
						...modalProps,
						modalBody: (
							<>
								<div className="section-d d-flex flex-column align-items-center gap-4">
									<div className="header mt-3">
										<h1>Opportunity Posted!</h1>
									</div>
									<div className="body d-flex flex-column align-items-center gap-4">
										<div className="img">
											<img src="/img/happy 1.png" alt="" />
										</div>
										<p>This Opportunity has been posted. Check your mails or Dashboard for updates.</p>
									</div>
									<div className="footer mb-4">
										<div data-bs-dismiss="modal">
											<Button
												text={
													<>
														<h2>Back to Dashboard</h2>
														<i className="fa-solid fa-arrow-right"></i>
													</>
												}
												onClick={() => navigate('/dashboard')}
											/>
										</div>
									</div>
								</div>
							</>
						),
					}}
				/>
			</div>
		</div>
	);
}

export default SectionC;
