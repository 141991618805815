import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'services/api';
import style from './css/navbar-comp.module.sass';
import { doHandleLogout } from 'views/dashboard/logout/Logout.js';
import Button from 'components/buttons/Button.jsx';
import service from 'services';

function NavCenterContent() {
	const dropdown = useRef();
	const active = 'navbar-comp_active__rzP7E';

	const dropdownHandler = () => {
		dropdown.current.classList.add(active);
	};

	return (
		<ul style={{ fontSize: '15px' }} className={`${style['con-links']} d-flex align-items-center gap-3`}>
			<Link to="/dashboard">
				<li>Opportunities</li>
			</Link>

			<li className={style.dropdown}>
				<div className={style['init-dropdown']} onClick={dropdownHandler}>
					Jobs <i className="fa-solid fa-chevron-down"></i>
				</div>

				<Link to="/dashboard/jobs/saved-jobs">
					<div className={`${style['dropdown-body']}`} ref={dropdown}>
						Saved Job
					</div>
				</Link>
			</li>

			<Link to="/dashboard/conversations">
				<li>Conversations</li>
			</Link>
			<Link to="/dashboard/projects">
				<li>My Projects</li>
			</Link>
		</ul>
	);
}

function NavRightContent() {
	const [profileImage, setProfileImage] = useState({});
	const userType = service.getUserType();

	useEffect(() => {
		if (userType === 'talent') {
			api.talents.profile
				.get()
				.then((response) => setProfileImage(response.data.result?.profile_picture))
				.catch()
				.finally();
		} else {
			api.freelancers.profile
				.get()
				.then((response) => setProfileImage(response.data.result?.profile_picture))
				.catch()
				.finally();
		}
	}, [userType]);
	return (
		<div className="d-flex align-items-center gap-3" style={{ fontSize: '15px' }}>
			<div className="">
				<Link to="/dashboard/projects/create">
					<li className="fw-bold">Create Project</li>
				</Link>
			</div>
			<div className="con-profile">
				<div className={style['con-user-img']}>
					<Link to="/dashboard/profile">
						<div className={style.bg}>
							<div className={style.img}>
								<img src={profileImage?.href_link || '/img/pic.jpg.png'} alt="" />
							</div>
						</div>
					</Link>
				</div>
			</div>

			{/* <div className={style['con-notify']}>
				<i className="fa-solid fa-bell"></i>
			</div> */}

			<div className="d-flex align-items-center gap-3">
				<Button text={<h2>Logout</h2>} small redirect="/login" onClick={doHandleLogout} />
			</div>
		</div>
	);
}

function NavSideContent() {
	const navigate = useNavigate();
	const logout = () => {
		doHandleLogout();
		navigate('/login');
	};
	return (
		<>
			<ul id="con-navbar-sidebar">
				<Link to="/dashboard">
					<li>Opportunities</li>
				</Link>

				<li className={style.dropdown}>
					<Link to="/dashboard/jobs/saved-jobs">
						<div className={`${style['dropdown-body']}`}>Saved Job</div>
					</Link>
				</li>

				<Link to="/dashboard/projects/create">
					<li>Create Project</li>
				</Link>
				<Link to="/dashboard/conversations">
					<li>Conversations</li>
				</Link>

				<Link to="/dashboard/projects">
					<li>My Projects </li>
				</Link>
				<li onClick={logout}>Logout</li>
			</ul>
		</>
	);
}

export const navbarComp = {
	centerContent: <NavCenterContent />,
	rightContent: <NavRightContent />,
	sidebarContent: <NavSideContent />,
};
