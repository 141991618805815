import React, { useEffect, useRef, useState } from 'react';
import ChatList from './Chatlist';
import ChatWindow from './ChatWindow';
import service from 'services';
import Navbar from 'views/_shared/navbar/Navbar';
import { navbarComp as RecruiterNavbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp';

import { navbarComp as TalentNavbarComp } from 'views/dashboard/talent/navbar-components/navbar-comp';
import Footer from 'views/_shared/footer/Footer';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import './conversations.css';
import { useNotifications } from 'ContextApi/NotificationProvider';

const Conversations = () => {
	service.setPageTitle('Conversations');
	const top = useRef(null);
	useEffect(() => service.goTo(top), []);
	const ACTIVE_ROLE = service.getUserType();

	const userType = service.getUserType();

	const { socket } = useNotifications();

	const { recruiterId, talentId } = useParams();
	const [chats, setChats] = useState([]);
	const [selectedChat, setSelectedChat] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		const initiateConversation = async () => {
			try {
				const newChat = await api.recruiter.conversations.initiateConversation({ talentId, recruiterId });
				const chat = newChat?.data;

				let userId;
				if (ACTIVE_ROLE === service.RECRUITER) {
					userId = recruiterId;
				} else {
					userId = talentId;
				}

				const data = {
					conversationId: chat?._id,
					userId: userId,
				};

				// Wrap the socket emit call in a promise
				const emitJoin = (data) => {
					return new Promise((resolve, reject) => {
						socket.emit('join', data, (response) => {
							if (response.error) {
								reject(response.error);
							} else {
								resolve(response);
							}
						});
					});
				};

				const joinResponse = await emitJoin(data);
				console.log('Join response:', joinResponse);

				// Wait for 3 seconds before setting the selected chat
				await new Promise((resolve) => setTimeout(resolve, 1000));
				setSelectedChat(chat);
			} catch (error) {
				console.error('Error initiating conversation:', error);
			} finally {
				setLoading(false);
			}
		};

		if (recruiterId && talentId) {
			initiateConversation();
		}
	}, [ACTIVE_ROLE, recruiterId, talentId, socket]);

	useEffect(() => {
		const handleMessageReceive = (newMessage) => {
			// Update chatsList.conversation array with the new message
			setChats((prevChats) => {
				const updatedConversations = prevChats.conversations.map((conversation) => {
					if (conversation._id === newMessage.conversationId._id) {
						// Update the conversation with the new message details
						return {
							...conversation,
							lastMessage: newMessage.text,
							lastMessageAt: newMessage.createdAt,
							recruiterUnreadMessages: newMessage.sender !== 'RECRUITER' ? conversation.recruiterUnreadMessages + 1 : conversation.recruiterUnreadMessages,
							talentUnreadMessages: newMessage.sender !== 'TALENT' ? conversation.talentUnreadMessages + 1 : conversation.talentUnreadMessages,
							adminUnreadMessages: newMessage.sender !== 'ADMIN' ? conversation.adminUnreadMessages + 1 : conversation.adminUnreadMessages,
						};
					}
					return conversation;
				});

				// Move the updated conversation to the top of the list
				const updatedChats = {
					...prevChats,
					conversations: updatedConversations.sort((a, b) => {
						if (a._id === newMessage.conversationId._id) return -1;
						return 1;
					}),
				};

				return updatedChats;
			});
		};

		socket.on('receive-message', handleMessageReceive);

		// Cleanup to remove the listener when the component unmounts or socket changes
		return () => {
			socket.off('receive-message', handleMessageReceive);
		};
	}, [socket]);

	useEffect(() => {
		const fetchConversations = async () => {
			setLoading(true);
			try {
				let res;
				if (ACTIVE_ROLE === service.RECRUITER) {
					res = await api.recruiter.conversations.getConversations();
				} else if (ACTIVE_ROLE === service.TALENT) {
					res = await api.talents.conversations.getConversations();
				} else {
					res = await api.freelancers.conversations.getConversations();
				}

				const conversations = res.data.conversations;
				setChats(res.data);

				// Emit 'join' for each conversation
				conversations.forEach((conversation) => {
					let userId;
					if (ACTIVE_ROLE === service.RECRUITER) {
						userId = conversation.recruiter?._id;
					} else {
						userId = conversation?.talent?._id;
					}

					const data = {
						conversationId: conversation._id,
						userId: userId,
					};

					socket.emit('join', data);
				});
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchConversations();
	}, [ACTIVE_ROLE, socket]);

	return (
		<div id="Onboarding_Talent_SectionC_Main_Component" ref={top} style={{ backgroundColor: '#F6F6F6' }} className="conversations-container">
			{userType === 'talent' || userType === 'freelancer' ? <Navbar {...TalentNavbarComp} /> : <Navbar {...RecruiterNavbarComp} />}

			<div className="con-margin-height" style={{ padding: '2%', width: '100%' }}>
				<div className="conversations-content con-section container-01">
					<div className={`chat-list-container ${selectedChat ? 'hide-on-mobile' : ''}`}>
						<ChatList chatsList={chats} onSelectChat={setSelectedChat} socket={socket} loading={loading} activeRole={ACTIVE_ROLE} />
					</div>
					<div className={`chat-window-container ${selectedChat ? 'show-on-mobile' : ''}`}>
						<ChatWindow socket={socket} chat={selectedChat} onBack={() => setSelectedChat(null)} loading={loading} />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Conversations;
