import './css/Recruiter.style.sass';
import Button from 'components/buttons/Button';
import Tab from 'components/buttons/tabs/Tabs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import api from 'services/api';
import { schema, values } from './schema';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import { useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import UploadViewer from 'components/upload-viewer/UploadViewer';

function Recruiter() {
	const [user, setUser] = useState({ first_name: '', last_name: '', email: '' });
	const [isActive, setIsActive] = useState(1);
	const [avatar, setAvatar] = useState('');
	const [cac, setCAC] = useState({
		file: '',
		issued_date: ''
	});
	const [isLoading, setIsLoading] = useState(false);
	const fileInputRef = useRef(null);
	const cacInputRef = useRef(null);

	function handleFileChange(event) {
		const file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			setAvatar(file);
			formData.append('file', file);
			api.recruiter.profile
				.uploadCompanyImage(formData)
				.then()
				.catch(() => console.error);
		}
	}

	function handleCaCDateChange(e) {
		setCAC((prev) => ({
			...prev, issued_date: e.target.value
		}))

	}

	function handleCACChange(event) {
		const file = event.target.files[0];
		if (file) {
			setCAC((prev) => ({
				...prev, file: file
			}));

		}
	}
	useEffect(() => {
		const formData = new FormData();
		formData.append('file', cac.file);
		formData.append('issued_date', cac.issued_date);
		if (cac.file && cac.issued_date) {
			api.recruiter.profile
				.uploadCompanyCac(formData)
				.then((res) => console.log(res))
				.catch((err) => console.log(err));
		}

	}, [cac])

	function handleAttachLogoClick() {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	}

	function handleAttachCACClick() {
		if (cacInputRef.current) {
			cacInputRef.current.click();
		}
	}

	const staffCapacity = ['1-10', '11-50', '51-250', '251-10k', '10k+'];

	const navigate = useNavigate();

	const formOptions = {
		initialValues: values,
		validationSchema: schema,
		onSubmit: doSubmit,
	};

	const forms = useFormik(formOptions);

	function doSubmit(postBody) {
		setIsLoading(true);
		postBody.staff_capacity = staffCapacity[isActive - 1];
		postBody.isOnboardingComplete = true;

		function successHandler() {
			setIsLoading(false);
			navigate('/dashboard');
		}

		function errorHandler(response) {
			const error = response.data.result;
			setIsLoading(false);
			console.error(error);
		}

		api.recruiter.onboarding(postBody).then(successHandler).catch(errorHandler);
	}
	async function handleSubmitAndNavigate() {
		try {
			setIsLoading(true);

			// Update the form data as needed
			const postBody = {
				...forms.values,
				staff_capacity: staffCapacity[isActive - 1],
				isOnboardingComplete: true,
			};

			await api.recruiter.onboarding(postBody);

			// Navigate to the desired route
			navigate('/dashboard/jobs/create');
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	}
	function getProfile() {
		function successHandler(response) {
			const result = response.data.result;
			setUser(result);
		}

		function errorHandler(error) {
			console.error(error);
		}

		api.recruiter.profile.get().then(successHandler).catch(errorHandler);
	}

	useEffect(getProfile, []);

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
};

	return (
		<div id="Onboarding_Recruiter_Main_Container">
			<div className="section">
				<div className="container-01">
					<div className="con-title">
						<h1>Let's get to know you!</h1>
						<p>Tell us who you are</p>
					</div>

					<div className="con-form">
						<div className="form">
							<form onSubmit={forms.handleSubmit}>
								<div>
									<div className="con-title">
										<img src="/img/svg/person1.svg" alt="" className="svg" />
										<h2>What are your names</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>First Name</label>
											</div>

											<input type="text" name="first_name" value={user.first_name} disabled />
										</div>

										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Last Name</label>
											</div>

											<input type="text" name="last_name" value={user?.last_name} disabled />
										</div>
									</div>
								</div>

								<div>
									<div className="con-title">
										<img src="/img/svg/multistaff.svg" alt="" className="svg" />

										<h2>Staff Capacity</h2>
									</div>

									<div className="d-lg-flex gap-2 mb-4">
										{staffCapacity.map((x, y) => (
											<Tab context={x} primaryOutline callback={() => setIsActive(y + 1)} activeTab={isActive === y + 1} key={y} />
										))}
									</div>
								</div>

								<div>
									<div className="con-title">
										<img src="/img/svg/contact1.svg" alt="" className="svg" />

										<h2>Company Info</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Company Name</label>

												<label className="error-msg mb-3">{forms.errors.company_name && forms.touched.company_name ? forms.errors.company_name : null}</label>
											</div>
											<input type="text" placeholder="Enter Company Name" name="company_name" className={forms.errors.company_name && forms.touched.company_name ? 'error' : ''} value={forms.values.company_name} onChange={forms.handleChange} />
										</div>

										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Position in Company</label>

												<label className="error-msg mb-3">{forms.errors.recruiter_position && forms.touched.recruiter_position ? forms.errors.recruiter_position : null}</label>
											</div>
											<input
												type="text"
												placeholder="Enter Position"
												name="recruiter_position"
												className={forms.errors.recruiter_position && forms.touched.recruiter_position ? 'error' : ''}
												value={forms.values.recruiter_position}
												onChange={forms.handleChange}
											/>
										</div>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Company Website</label>

												<label className="error-msg mb-3">{forms.errors.company_website && forms.touched.company_website ? forms.errors.company_website : null}</label>
											</div>
											<input
												type="text"
												placeholder="Enter Company Website Link"
												name="company_website"
												className={forms.errors.company_website && forms.touched.company_website ? 'error' : ''}
												value={forms.values.company_website}
												onChange={forms.handleChange}
											/>
										</div>

										<i className="control-form"></i>
									</div>
									<div className="d-flex align-items-center gap-4">
										<div className="d-flex gap-3 align-items-center">
											<input type="file" accept="image/*" name="company_logo" className="d-none" onChange={handleFileChange} ref={fileInputRef} />
											<Button
												text={
													<>
														<h1>Attach Logo</h1>
														<i className="fa-solid fa-paperclip"></i>
													</>
												}

												onClick={handleAttachLogoClick}
											/>
											{avatar && <UploadViewer filename={avatar?.name} callback={() => setAvatar('')} />}
										</div>

									</div>
									<div className="d-lg-flex justify-content-between gap-4  mt-3">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>CAC Certificate</label>

												{/* <label className="error-msg mb-3">{forms.errors.company_website && forms.touched.company_website ? forms.errors.company_website : null}</label> */}
											</div>
											<div className="d-flex align-items-center gap-3 mb-3">
												<input type="file" accept="image/*" name="company_cac" className="d-none" onChange={handleCACChange} ref={cacInputRef} />
												<div>
													<Button
														text={
															<>
																<h1>Attach Certificate</h1>
																<i className="fa-solid fa-paperclip"></i>
															</>
														}
														primaryNull
														onClick={handleAttachCACClick}

													/>
												</div>
												{cac.file && <UploadViewer filename={cac?.file.name} callback={() => setCAC('')} />}
											</div>
											<label> Certificate Issue Date</label>

											<input
												type="date"
												placeholder="Certificate issue date"
												value={cac.issued_date}
												onChange={handleCaCDateChange}
												name="issue_date"
												style={{ width: '80%' }}
														max={getCurrentDate()}
											/>

										</div>

										<i className="control-form"></i>
									</div>
								</div>

								<div>
									<div className="con-title">
										<img src="/img/svg/contact1.svg" alt="" className="svg" />

										<h2>Contact Details</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Phone Number</label>

												<label className="error-msg mb-3">{forms.errors.phone_number && forms.touched.phone_number ? forms.errors.phone_number : null}</label>
											</div>
											<input type="text" placeholder="Enter Phone Number" name="phone_number" className={forms.errors.phone_number && forms.touched.phone_number ? 'error' : ''} value={forms.values.phone_number} onChange={forms.handleChange} />
										</div>

										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Email Address</label>
											</div>
											<input type="text" value={user?.email} disabled />
										</div>
									</div>
								</div>

								<div className="control-form d-lg-flex align-items-center gap-3">
									<div className="mb-4 mb-lg-0">
										<Button
											text={
												<>
													<h1>Go to Dashboard</h1>
													<i className="fa-solid fa-arrow-right"></i>
												</>
											}
											primaryNull
											disabled={isLoading}
											onClick={forms.handleSubmit}
										/>
									</div>

									<div>
										<Button
											text={<LoaderButton isLoading={isLoading} text="Create a Job Post" outerConStyle={{ width: '180px' }} />}
											// onSubmit={forms.handleSubmit}
											// onClick={navigate('/dashboard/jobs/create')}
											onClick={handleSubmitAndNavigate}
											disabled={isLoading}
										/>

									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Recruiter;

